<template>
  <div class="fixed-bar">
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      right
      class="hidden-md-and-up fixed--bar-mobile"
    >
      <v-list nav dense class="fixed--bar-mobile">
        <v-list-item-group v-model="group">
          <v-list-item
            v-for="(item, i) in menuItems"
            :key="i"
            :to="item.route"
            :href="item.routeToDashboard">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar light max-height="70px" class="fixed-bar">
      <v-col lg="10" offset-lg="1" class="d-flex">
        <v-toolbar-title class="platform-wrapper align-self-center">
          <router-link class="platform" to="/">
            <img class="header__logo" src="../../assets/images/beginning.png" alt="">
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-tabs color="black">
            <v-tab class="item-title"
                   :class="{'item-button' : item.title === `Вход` || item.title === `Вхід`, 'item-point' : i !== 0 && i !== menuItems.length - 1}"
                   v-for="(item, i) in menuItems"
                   :key="i" :to="item.route"
                   :href="item.routeToDashboard">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </v-toolbar-items>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>
      </v-col>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data () {
    return {
      expand: false,
      headerTitle: 'Платформа',
      drawer: false,
      group: null,
      linkToDashboard: process.env.VUE_APP_DASHBOARD + '/auth',
      linkPatientReg: process.env.VUE_APP_DASHBOARD + '/auth?userType=patient',
      linkPsychologistReg: process.env.VUE_APP_DASHBOARD + '/auth?userType=psychologist'
    }
  },
  mounted () {
    document.querySelector('.v-tabs-slider').classList.add('d-none')
  },
  watch: {
    '$route' (to) {
      switch (to.name) {
        case 'MainPage':
        case 'contacts':
        case 'FAQ':
          document.querySelector('.v-tabs-slider').classList.add('d-none')
          break
        default:
          document.querySelector('.v-tabs-slider').classList.remove('d-none')
      }
    }
  },
  computed: {
    menuItems () {
      return [
        {
          title: this.$vuetify.lang.t('$vuetify.main.header.menuItem1'),
          route: '/about',
          icon: ''
        },
        {
          title: this.$vuetify.lang.t('$vuetify.main.header.menuItem2'),
          route: '/therapists',
          icon: ''
        },
        {
          title: this.$vuetify.lang.t('$vuetify.main.header.menuItem3'),
          route: '/psychologist',
          icon: ''
        },
        {
          title: this.$vuetify.lang.t('$vuetify.main.header.menuItem4'),
          route: '/blog',
          icon: ''
        },
        {
          title: this.$vuetify.lang.t('$vuetify.main.header.menuItem5'),
          route: '/business',
          icon: ''
        },
        {
          title: this.$vuetify.lang.t('$vuetify.main.header.EnterButton'),
          route: '',
          icon: 'mdi-lock',
          routeToDashboard: this.linkToDashboard
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.v-slide-group__content {
  background: #dbe3eb !important
}
.v-item-group .v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
</style>

<style lang="sass" scoped>
$lightColour: #dbe3eb
$secondaryColour: #2fa3b5
.platform-wrapper
  width: 180px
.platform
  display: block
.header__logo
  display: block
  margin: auto 0
  max-width: 180px
  max-height: 50px

.fixed-bar
  position: sticky
  position: -webkit-sticky
  top: 0
  z-index: 2
  background-color: $lightColour !important

.fixed--bar-mobile
  position: fixed !important
  z-index: 3

.item-title
  min-width: 67px
  position: relative
  text-transform: none
  font-size: 1rem
  font-weight: normal
  color: #575756 !important
  letter-spacing: 0

.item-point::after
  content: ''
  display: block
  position: absolute
  left: 0
  transform: rotateZ(45deg)
  width: 3px
  height: 3px
  background-color: #575756

.item-button
  padding: 0 10px !important
  background: $secondaryColour
  height: 40px
  width: 90px
  align-self: center
  border-radius: 10px
  color: #ffffff !important

@media (min-width: 960px) and (max-width: 1025px)
  .platform-wrapper
    width: 100px
  .platform
    width: 37px
    margin: 0 auto
    overflow: hidden
</style>
